import cutter from 'cookie-cutter';
import Cookies from 'cookies';

const cookieStore = (req, res) => {
  const isServerSide = !!req || !!res;
  const store = isServerSide ? new Cookies(req, res) : cutter;
  return store;
};

export default cookieStore;
