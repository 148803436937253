import createApiClient from '@stargazer/core-ui-v2/src/lib/api';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const defaultOptions = {
  tokenName: 'stargazer/auth',
  baseURL: `${publicRuntimeConfig.API_URL}/accounts/v1`,
};

export const create = options => {
  options = { ...defaultOptions, ...options };
  const api = createApiClient(options);
  const { httpClient, tokenStore } = api;

  return {
    ...api,

    me: () => httpClient.request({ url: '/me' }),

    refreshToken: async () => {
      const { user, token } = await httpClient.request({
        method: 'post',
        url: '/refresh-token',
      });
      tokenStore.setToken(token);
      return { user, token };
    },

    signup: async ({ firstname, lastname, email, password, accountType }) => {
      const { user, token } = await httpClient.request({
        method: 'post',
        url: '/signup',
        data: {
          firstname,
          lastname,
          email,
          password,
          accountType,
        },
      });
      tokenStore.setToken(token);
      return { user, token };
    },
    verifyEmail: ({ email, token }) =>
      httpClient.request({
        method: 'post',
        url: '/verify-email',
        data: { email, token },
      }),
    signin: async ({ email, password }) => {
      const { user, token } = await httpClient.request({
        method: 'post',
        url: '/signin',
        data: {
          email,
          password,
        },
      });
      tokenStore.setToken(token);
      return { user, token };
    },
    signout: async () => {
      tokenStore.setToken('');
    },
    forgotPassword: ({ client_id, redirect_url, email }) =>
      httpClient.request({
        method: 'post',
        url: '/forgot-password',
        data: { client_id, redirect_url, email },
      }),
    resetPassword: ({ password, token }) =>
      httpClient.request({
        method: 'post',
        url: '/reset-password',
        data: { password, token },
      }),
    updateDetails: ({ firstname, lastname }) =>
      httpClient.request({
        method: 'post',
        url: '/update-details',
        data: { firstname, lastname },
      }),
    updatePassword: ({ newPassword, currentPassword }) =>
      httpClient.request({
        method: 'post',
        url: '/update-password',
        data: { newPassword, currentPassword },
      }),

    //METAMASK
    cryptoGetNonce: async ({ publicAddress }) => {
      const res = await httpClient.request({
        method: 'post',
        url: 'crypto/getNonce',
        data: {
          publicAddress,
        },
      });
      return res;
    },
    metamaskSignin: async ({ signature, publicAddress, accountType }) => {
      const { user, token } = await httpClient.request({
        method: 'post',
        url: 'crypto/signIn',
        data: {
          cryptoData: {
            signature,
            publicAddress,
          },
          accountType,
          provider: 'metamask',
        },
      });
      tokenStore.setToken(token);
      return { user, token };
    },
    metamaskSignup: async ({ firstname, lastname, email, signature, publicAddress, accountType }) => {
      const { message } = await httpClient.request({
        method: 'post',
        url: 'crypto/signUp',
        data: {
          firstname,
          lastname,
          email,
          cryptoData: {
            signature,
            publicAddress,
          },
          provider: 'metamask',
          accountType,
        },
      });
      return { message };
    },
    torusSignup: async ({ torusUserData, signature, publicAddress, accountType }) => {
      const { email } = torusUserData;
      const { message } = await httpClient.request({
        method: 'post',
        url: 'crypto/signUp',
        data: {
          email,
          cryptoData: {
            signature,
            publicAddress,
            torusUserData,
          },
          provider: 'torus',
          accountType,
        },
      });
      return { message };
    },
    torusSignIn: async ({ signature, publicAddress, email, accountType }) => {
      const { user, token } = await httpClient.request({
        method: 'post',
        url: 'crypto/signIn',
        data: {
          cryptoData: {
            signature,
            publicAddress,
            email,
          },
          accountType,
          provider: 'torus',
        },
      });
      tokenStore.setToken(token);
      return { user, token };
    },
    setCryptoStatus: async ({ publicAddress, status }) => {
      const res = await httpClient.request({
        method: 'post',
        url: 'crypto/setCryptoStatus',
        data: {
          publicAddress,
          status,
        },
      });
      return res;
    },
    link: async ({ cryptoData }) => {
      const res = await httpClient.request({
        method: 'post',
        url: 'crypto/link',
        data: {
          cryptoData,
        },
      });
      return res;
    },
    unlink: async () => {
      const res = await httpClient.request({
        method: 'post',
        url: 'crypto/unlink',
      });
      return res;
    },
  };
};

export default create();
