import moment from 'moment';
import cookieStore from './cookieStore';

const TOKEN_COOKIE = 'token';

const storeCreator = (tokenName = TOKEN_COOKIE, { req, res } = {}) => {
  const cookies = cookieStore(req, res);
  const setToken = token =>
    cookies.set(tokenName, token, {
      expires: moment().add(14, 'days').toDate(),
      httpOnly: false,
    });
  const deleteToken = () => cookies.set(tokenName, '');
  const getToken = () => cookies.get(tokenName);

  return {
    setToken,
    getToken,
    deleteToken,
  };
};

export default storeCreator;
