import axios from 'axios';

export default (options, { getToken, onSignOut }) => {
  const client = axios.create(options);

  client.interceptors.request.use(
    async config => {
      if (getToken) {
        const token = getToken();

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      return config;
    },
    error => Promise.reject(error),
  );

  client.interceptors.response.use(
    response => response.data,
    error => {
      // TODO: review what kind of responses we need to transform from api server and throw proper Error
      const transformedError = {
        code: error.code,
        message: error.message,
      };

      // Could be missing for network errors, ie: timeout
      if (error.response) {
        Object.assign(transformedError, {
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }

      // Some endpoints return information on data field, ie: user/login (data: { error: true, message: 'Already exists' })
      if (error.response && error.response.data) {
        Object.assign(transformedError, error.response.data);
      }

      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        if (onSignOut) {
          onSignOut();
        }
      }

      return Promise.reject(transformedError);
    },
  );

  return client;
};
