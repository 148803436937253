import createHttpClient from './httpClient';
import tokenStoreCreator from './tokenStore';

/**
 * Creates an API client.
 *
 * You can pass all the axios options in the options param,
 * plus some custom ones
 *
 * @param {object} options
 *  @property {any} * - all params valid for axios client.
 *  @property {string} tokenName - cookie name where to store the token.
 *  @property {Request} req - Request when used from server side.
 *  @property {Response} res - Response when used from server side.
 *  @property {function} onSignOut - callback to be called after
 *    signing out.
 * @returns ApiClient
 */
const create = ({ tokenName, req, res, ...options } = {}) => {
  const tokenStore = tokenStoreCreator(tokenName, { req, res });
  const { getToken } = tokenStore;
  const signOut = () => {
    tokenStore.deleteToken();
    if (options.onSignOut) {
      options.onSignOut();
    }
  };
  const httpClient = createHttpClient(options, {
    getToken,
    onSignOut: signOut,
  });

  const api = { httpClient, tokenStore };

  api.isAuthenticated = () => !!api.tokenStore.getToken();
  api.setToken = token => api.tokenStore.setToken(token);
  api.call = (...args) => httpClient.request(...args);
  api.onSignOut = fn => {
    options.onSignOut = fn;
  };
  api.auth = {
    signOut: signOut,
  };

  return api;
};

export default create;
